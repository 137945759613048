import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router";
import "./App.scss";
import Accounts from "./Components/Accounts/Accounts";
import LandingPage from "./Components/LandingPage/LandingPage";
import LoginPage from "./Components/LoginPage/LoginPage";
import ContextProvider from "./Components/Context/Context";

function App() {
  const history = useHistory();
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={LoginPage} />
        {/* <Route exact path="/" component={LandingPage} /> */}
        <Route
          path="/"
          render={() => (
            <ContextProvider>
              <Accounts />
            </ContextProvider>
          )}
        />
        <Redirect to="/about" />
      </Switch>
    </div>
  );
}

export default App;
